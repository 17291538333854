import { RefObject, useCallback, useEffect, useReducer, useRef, useState } from "react";
import styles from "../../css/dist/index.module.css";
import { User } from "../../../../lib";
import { OnEventSetter, ReactTransitionStarter, TransitionState } from "../../types";
import { addPitchView, getAllUsersWithPCs, getPitch, getPitchViews } from "../../connections";
import { Animated, AnimationString } from "react-animated-css";
import { ErrorNotif } from "../util/errornotif";
import { MiniProfile } from "../util/profileparts";
import numeral from "numeral";
import { LoadingIcon } from "../util/loadingicon";

const getPitchIDFromInfo = (pitchUploaderIX: number, rowIX: number) => {
  return `${pitchUploaderIX}x${rowIX}`;
};

interface SuggestionData {
  pc: number,
  user: User,
  rowIX: number,
};




export const Network = ({ currUser, startTransition, setTransitionState, setPath, path, mainContainerRef, isMobile }: { currUser: User, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, mainContainerRef: RefObject<HTMLDivElement>, isMobile: boolean }) => {
  const [pitchUploaderIX, updatePitchUploaderIX] = useReducer((state: number, action: { type: "++" | "--", numUsers: number } | { type: "SET", newValue: number }) => {
    switch (action.type) {
      case "++": return (state + 1) % action.numUsers;
      case "--": return (state - 1 + action.numUsers) % action.numUsers;
      case "SET": return action.newValue;
    }
  }, 0);




  
  const [preloaded, updatePreloaded] = useReducer((state: {[pitchid: string]: string}, action: { type: "RESERVE", data: { pitchid: string } } | { type: "PLACE", data: { pitchid: string, path: string } }) => {
    switch (action.type) {
      case "RESERVE":
        return { ...state, ...{ [action.data.pitchid]: "" } };
      case "PLACE":
        return { ...state, ...{ [action.data.pitchid]: action.data.path } };
    }
  }, {} as {[pitchid: string]: string});




  const [errorText, setErrorText] = useState<string | undefined>();




  const [suggestions, updateSuggestions] = useReducer((state: SuggestionData[], action: { type: "INITIALIZE", userAndPCList: [User, number][] } | { type: "EDIT_RIX", userIndex: number, newValue: number }) => {
    switch (action.type) {
      // no break bc we have return which ends case already
      case "INITIALIZE":
        return action.userAndPCList.map(([user, pc]): SuggestionData => {
          return {
            pc,
            user,
            rowIX: 0,
          };
        });
      case "EDIT_RIX":
        state[action.userIndex].rowIX = action.newValue;
        return state;
      default:
        return state;
    }
  }, []);

  const intializeSuggestionsCallback = useCallback(async () => {
    updateSuggestions({
      type: "INITIALIZE",
      userAndPCList: (await getAllUsersWithPCs())??[],
    });
  }, []);

  useEffect(() => { intializeSuggestionsCallback(); }, [intializeSuggestionsCallback]);





  const MIN_TIME_BETWEEN_SCROLLS_MS = 2000;
  const ANIM_TIME_MS = 500;  // time is fine iff less than between scroll time
  const [vidAnimationIn, setVidAnimationIn] = useState("fadeIn" as AnimationString);
  const [vidAnimationOut, setVidAnimationOut] = useState("fadeOut" as AnimationString);





  const [horizScrollState, updateHorizScrollState] = useReducer((state: boolean) => { return !state; }, false);





  const [vidVisible, setVidVisible] = useState(true);
  
  const MIN_DIFF_FOR_SCROLL_PX = window.innerWidth / 3; const _MDFSPX = MIN_DIFF_FOR_SCROLL_PX;

  const [downPos, setDownPos] = useState({ x: 0, y: 0 });
  const [diff, setDiff] = useState({ x: 0, y: 0 });

  const [lastScroll, updateLastScroll] = useReducer(() => { return Date.now(); }, Date.now());

  const handleTouchStart = useCallback((evt: TouchEvent) => {
    if (!(evt.target instanceof HTMLVideoElement)) return;

    const firstTouch = evt.touches[0];

    setDownPos({ x: firstTouch.clientX, y: firstTouch.clientY });
  }, []);

  const handleTouchMove = useCallback((evt: TouchEvent) => {
    if (!(evt.target instanceof HTMLVideoElement)) return;
    
    if (!downPos.x || !downPos.y) return;
    
    const xUp = evt.touches[0].clientX;                                    
    const yUp = evt.touches[0].clientY;

    const xDiff = downPos.x - xUp;
    const yDiff = downPos.y - yUp;

    if (containerRef.current) {
      if (Math.max(Math.abs(xDiff), Math.abs(yDiff)) >= _MDFSPX) {
        if (Math.abs(xDiff) > Math.abs(yDiff))
          containerRef.current.style.transform = `translateX(${-xDiff}px)`;
        else
          containerRef.current.style.transform = `translateY(${-yDiff}px)`;
      } else {
        containerRef.current.style.transform = "";
      }
    }

    setDiff({ x: xDiff, y: yDiff });
  }, [downPos, _MDFSPX]);

  const rawRightScroll = useCallback(() => {
    setTimeout(() => {
      const newValue = ((suggestions[pitchUploaderIX]?.rowIX??0) + 1) % (suggestions[pitchUploaderIX]?.pc??1);
      updateSuggestions({ type: "EDIT_RIX", userIndex: pitchUploaderIX, newValue });
      updateHorizScrollState();
    }, ANIM_TIME_MS);
    setVidAnimationOut("fadeOutRight");
    setVidAnimationIn("fadeInLeft");
  }, [pitchUploaderIX, suggestions]);

  const rawLeftScroll = useCallback(() => {
    setTimeout(() => {
      const newValue = ((suggestions[pitchUploaderIX]?.rowIX??0) - 1 + (suggestions[pitchUploaderIX]?.pc??0)) % (suggestions[pitchUploaderIX]?.pc??1);
      updateSuggestions({ type: "EDIT_RIX", userIndex: pitchUploaderIX, newValue });
      updateHorizScrollState();
    }, ANIM_TIME_MS);
    setVidAnimationOut("fadeOutLeft");
    setVidAnimationIn("fadeInRight");
  }, [pitchUploaderIX, suggestions]);

  const rawUpScroll = useCallback(() => {
    setTimeout(() => {
      updatePitchUploaderIX({ type: "--", numUsers: suggestions.length });
    }, ANIM_TIME_MS);
    setVidAnimationOut("fadeOutUp");
    setVidAnimationIn("fadeInUp");
    setVerticalScrolled(true);
  }, [suggestions.length]);

  const rawDownScroll = useCallback(() => {
    setTimeout(() => {
      updatePitchUploaderIX({ type: "++", numUsers: suggestions.length });
    }, ANIM_TIME_MS);
    setVidAnimationOut("fadeOutDown");
    setVidAnimationIn("fadeInDown");
    setVerticalScrolled(true);
  }, [suggestions.length]);

  const completeScroll = useCallback(() => {
    setVidVisible(false);
    setTimeout(() => {
      setVidVisible(true);
      if (videoRef.current) videoRef.current.style.filter = "drop-shadow(0 0 0 transparent)";
      if (!containerRef.current) return;
      containerRef.current.style.transform = "";
      containerRef.current.style.transition = "";
    }, ANIM_TIME_MS);

    setDownPos({ x: 0, y: 0 });
  }, []);

  const failScrollValdChk = useCallback(() => {
    setDownPos({ x: 0, y: 0 });
    if (containerRef.current) containerRef.current.style.transform = "";
  }, []);

  const generalScrollValdChk = useCallback((alertError: boolean) => {
    if (!suggestions) {
      failScrollValdChk();
      return false;
    }

    if (Date.now() - lastScroll < MIN_TIME_BETWEEN_SCROLLS_MS + ANIM_TIME_MS) {  // add anim time bc they start seeing the video from when the anim time elapses, so min time viewing a video must compensate
      if (alertError) setErrorText(`Give this user's pitch at least ${MIN_TIME_BETWEEN_SCROLLS_MS / 1000} seconds of consideration before scrolling.`);
      failScrollValdChk();
      return false;
    }

    return true;
  }, [lastScroll, suggestions, failScrollValdChk]);

  const handleTouchEnd = useCallback((evt: TouchEvent) => {
    if (!(evt.target instanceof HTMLVideoElement) || !generalScrollValdChk(true)) return;

    if (Math.max(Math.abs(diff.x), Math.abs(diff.y)) < _MDFSPX) return;

    if (Math.abs(diff.x) > Math.abs(diff.y)) {
      if (diff.x < 0) {
        rawRightScroll();
      } else {
        rawLeftScroll();
      }
    } else {
      if (diff.y < 0) {
        rawDownScroll();
      } else {
        rawUpScroll();
      }
    }

    updateLastScroll();
    
    completeScroll();
  }, [diff.x, diff.y, _MDFSPX, rawUpScroll, rawDownScroll, rawLeftScroll, rawRightScroll, completeScroll, generalScrollValdChk]);
  
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("touchstart", handleTouchStart, false);
    window.addEventListener("touchmove", handleTouchMove, false);
    window.addEventListener("touchend", handleTouchEnd, false);

    return (() => {
      window.removeEventListener("touchstart", handleTouchStart, false);
      window.removeEventListener("touchmove", handleTouchMove, false);
      window.removeEventListener("touchend", handleTouchEnd, false);
    });
  }, [handleTouchStart, handleTouchMove, handleTouchEnd]);

  const handleWheel = useCallback((evt: WheelEvent) => {
    if (!generalScrollValdChk(false)) return;

    const delta = evt.deltaY;
    
    if (delta < 0) {
      rawDownScroll();
    } else if (delta > 0) {
      rawUpScroll();
    }

    updateLastScroll();

    completeScroll();
  }, [rawDownScroll, rawUpScroll, generalScrollValdChk, completeScroll]);

  useEffect(() => {
    document.addEventListener("wheel", handleWheel);

    return (() => {
      document.removeEventListener("wheel", handleWheel);
    });
  }, [handleWheel]);

  const fullLeftScroll = useCallback(() => {
    if (!generalScrollValdChk(true)) return;

    rawLeftScroll();

    updateLastScroll();
    completeScroll();
  }, [rawLeftScroll, generalScrollValdChk, completeScroll]);

  const fullRightScroll = useCallback(() => {
    if (!generalScrollValdChk(true)) return;

    rawRightScroll();
    
    updateLastScroll();
    completeScroll();
  }, [rawRightScroll, generalScrollValdChk, completeScroll]);



  const [preloadedDone, setPreloadedDone] = useState(false);
  const [vidSrc, setVidSrc] = useState(["", ""]);
  const loadPitch = useCallback(async (uploaderIX: number, rowIX: number) => {
    if (!suggestions[uploaderIX]) return;

    const isCurrent = pitchUploaderIX === uploaderIX && suggestions[pitchUploaderIX].rowIX === rowIX;

    const pitchID = getPitchIDFromInfo(uploaderIX, rowIX);
    if (preloaded[pitchID]) {
      if (isCurrent) setVidSrc([preloaded[pitchID], pitchID]);
      return;
    }

    // updatePreloaded({ type: "RESERVE", data: { pitchid: pitchID } });

    const pitchPath = URL.createObjectURL(await getPitch(suggestions[uploaderIX].user.uid, rowIX));
    if (window.location.pathname !== "/network") return;
    updatePreloaded({ type: "PLACE", data: { pitchid: pitchID, path: pitchPath } });

    if (isCurrent) setVidSrc([pitchPath, pitchID]);
    // eslint-disable-next-line
  }, [suggestions, preloadedDone, pitchUploaderIX, horizScrollState]);






  useEffect(() => {
    if (pitchUploaderIX >= suggestions.length) return;
    
    const { rowIX: userRowIX, pc: userPC } = suggestions[pitchUploaderIX];

    setPreloadedDone(false);

    let tasks = [];
    if (userPC > 1) tasks.push(loadPitch(pitchUploaderIX, (userRowIX + 1) % userPC));
    if (userPC > 2) tasks.push(loadPitch(pitchUploaderIX, (userRowIX - 1 + userPC) % userPC));
    
    if (suggestions.length > 2) {
      const pitch1u = (pitchUploaderIX + 1) % suggestions.length;
      tasks.push(loadPitch(pitch1u, suggestions[pitch1u].rowIX));

      const pitch1d = (pitchUploaderIX - 1 + suggestions.length) % suggestions.length;
      tasks.push(loadPitch(pitch1d, suggestions[pitch1d].rowIX));
    } else if (suggestions.length > 1) {
      const pitch1u = (pitchUploaderIX + 1) % suggestions.length;
      tasks.push(loadPitch(pitch1u, suggestions[pitch1u].rowIX));
    }
    tasks.push(loadPitch(pitchUploaderIX, userRowIX));
    
    Promise.all(tasks).then(() => {
      setPreloadedDone(true);
    });

    mainContainerRef.current?.scrollTo(0, 0);

    setTimeout(() => {
      const vidObj = videoRef.current;
      if (!vidObj) return;

      vidObj.style.transition = `filter ${MIN_TIME_BETWEEN_SCROLLS_MS}ms`;
      vidObj.style.filter = "drop-shadow(0 0 10px oklch(var(--a)))";

      setTimeout(() => {
        vidObj.style.transition = "500ms";
        vidObj.style.filter = "drop-shadow(0 0 10px oklch(var(--p)))";
      }, MIN_TIME_BETWEEN_SCROLLS_MS);
    }, ANIM_TIME_MS);
  }, [pitchUploaderIX, horizScrollState, loadPitch, suggestions, mainContainerRef]);





  const [updatedUsingPath, setUpdatedUsingPath] = useState(false);
  useEffect(() => {
    if (updatedUsingPath || !suggestions) return;

    const pathSplit = path.split("/");
    if (pathSplit.length !== 4) {
      // no resetting any shit here needed yeyeeyeyye
      return;
    }

    const [,, targetUID, rowIX] = pathSplit;
    
    let targetSuggIX = 0;
    for (let ix=0;ix<suggestions.length;ix++) {
      if (suggestions[ix].user.uid === targetUID) {
        targetSuggIX = ix;
        break;
      }
    }

    if (!suggestions[targetSuggIX]) return;

    updatePitchUploaderIX({ type: "SET", newValue: targetSuggIX });
    suggestions[targetSuggIX].rowIX = parseInt(rowIX);

    setUpdatedUsingPath(true);
  }, [path, suggestions, pitchUploaderIX, updatedUsingPath]);





  const [pitchViews, setPitchViews] = useState(0);
  useEffect(() => {
    if (!suggestions || !suggestions[pitchUploaderIX]) return;
    const uploaderUID = suggestions[pitchUploaderIX].user.uid, rowIX = suggestions[pitchUploaderIX].rowIX;
    getPitchViews(uploaderUID, rowIX).then((views) => {
      setPitchViews(views??0);
    });
    addPitchView(uploaderUID, rowIX);
  }, [pitchUploaderIX, suggestions, horizScrollState]);


  const onErrorNotifClose = useCallback(() => {
    setErrorText(undefined);
  }, []);

 



  const [verticalScrolled, setVerticalScrolled] = useState(false);





  // 0 = center, -1 = most left, 1 = most right
  const [mousePosX, updateMousePosX] = useReducer((state: number, evt: MouseEvent) => {
    const zeroToTwo = evt.clientX * 2 / window.innerWidth;

    return zeroToTwo - 1;
  }, 0);
  useEffect(() => {
    document.addEventListener("mousemove", updateMousePosX);

    return (() => {
      document.removeEventListener("mousemove", updateMousePosX);
    });
  }, [updateMousePosX]);





  const [vidMuted, toggleVidMuted] = useReducer((state: boolean) => { return !state; }, true);





  // useEffect(() => {
  //   if (!preloadedDone) return;
  //   setVidSrc(preloaded[getPitchIDFromInfo(pitchUploaderIX, suggestions[pitchUploaderIX]?.rowIX ?? (-1))]);
  // }, [preloaded, pitchUploaderIX, suggestions, horizScrollState]);





  const MAX_PAN_BG_OPACITY_PCT = 0.2; const _MPBGOP = MAX_PAN_BG_OPACITY_PCT;

  return (<div ref={containerRef} className={`${styles["h-full"]} ${styles["overflow-hidden"]}`}>
    {
      (!isMobile)
      ? (<div className={`${styles["absolute"]} ${styles["left-0"]} ${styles["right-0"]} ${styles["top-0"]} ${styles["bottom-0"]} ${styles["flex"]}`}>
          <div className={`${styles["flex-1"]} ${styles["h-full"]} ${styles["bg-primary"]}`} style={{ mask: `linear-gradient(to right, rgba(255, 255, 255, ${(- mousePosX) * _MPBGOP}), transparent)` }}></div>
          <div className={`${styles["flex-1"]} ${styles["h-full"]} ${styles["bg-primary"]}`} style={{ mask: `linear-gradient(to right, transparent, rgba(255, 255, 255, ${(mousePosX) * _MPBGOP}))` }}></div>
        </div>)
      : null
    }

    {
      (!isMobile)
      ? (<Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={!verticalScrolled} className={`${styles["absolute"]} ${styles["h-full"]} ${styles["w-full"]}`}>
          <span className={`${styles["block"]} ${styles["absolute"]} ${styles["rotate-90"]} ${styles["text-xs"]} ${styles["right-0"]} ${styles["top-1/2"]} ${styles["w-min"]} ${styles["text-nowrap"]}`}>{"<"}  Scroll  {">"}</span>
        </Animated>)
      : null
    }

    <Animated animationIn={vidAnimationIn} animationOut={vidAnimationOut} animationOutDuration={ANIM_TIME_MS} animationInDuration={ANIM_TIME_MS} isVisible={vidVisible} className={`${styles["relative"]} ${styles["h-full"]} ${styles["z-10"]}`}>

      <div className={`${styles["flex"]} ${styles["content-center"]} ${styles["justify-center"]} ${styles["h-full"]} ${styles["w-dvw"]} ${styles["overflow-hidden"]} ${styles["p-5"]}`}>

        {
          (!isMobile)
          ? (<div className={`${styles["absolute"]} ${styles["left-0"]} ${styles["right-0"]} ${styles["top-0"]} ${styles["bottom-0"]} ${styles["flex"]} ${styles["justify-between"]} ${styles["!z-10"]}`}>
              {
                <div className={`${styles["w-[20dvw]"]} ${styles["h-full"]} ${styles["flex"]} ${styles["items-center"]} ${styles["justify-center"]}`}>
                  <button className={`${styles["btn"]} ${styles["btn-ghost"]}`} onClick={fullLeftScroll}>
                    {"<<<"}
                  </button>
                </div>
              }
              {
                <div className={`${styles["w-[20dvw]"]} ${styles["h-full"]} ${styles["flex"]} ${styles["items-center"]} ${styles["justify-center"]}`}>
                  <button className={`${styles["btn"]} ${styles["btn-ghost"]}`} onClick={fullRightScroll}>
                    {">>>"}
                  </button>
                </div>
              }
            </div>)
          : null
        }
        
        <div className={`${styles["flex"]} ${styles["content-center"]} ${styles["justify-center"]} ${styles["h-full"]} ${styles["relative"]}`}>

          <video ref={videoRef} className={`${styles["select-none"]} ${styles["rounded-xl"]}`} style={{ aspectRatio: "9 / 16", backgroundImage: "linear-gradient(to right, #000, #000)" }} src={(!vidSrc[0] || vidSrc[1] !== getPitchIDFromInfo(pitchUploaderIX, suggestions[pitchUploaderIX].rowIX)) ? "" : vidSrc[0]} autoPlay muted={vidMuted} loop webkit-playsinline playsInline></video>

          {
            (!vidSrc[0] || vidSrc[1] !== getPitchIDFromInfo(pitchUploaderIX, suggestions[pitchUploaderIX].rowIX))
            ? (<div className={`${styles["absolute"]} ${styles["left-1/2"]} ${styles["top-1/2"]} ${styles["-translate-x-1/2"]} ${styles["-translate-y-1/2"]}`}>
              <LoadingIcon></LoadingIcon>
            </div>)
            : null
          }

          <div className={`${styles["absolute"]} ${styles["top-2"]} ${styles["left-1/2"]} ${styles["-translate-x-1/2"]} ${styles["flex"]} ${styles["gap-2"]} ${styles["!z-20"]}`}>
            <div className={`${styles["badge"]} ${styles["min-w-24"]} ${styles["badge-primary"]}`}>{numeral(pitchViews).format("0a")} View{pitchViews === 1 ? "" : "s"}</div>
            <div className={`${styles["badge"]} ${styles["min-w-24"]} ${styles["cursor-pointer"]} ${styles["badge-accent"]}`} onClick={toggleVidMuted}>{ vidMuted ? "Unmute" : "Mute" }</div>
          </div>

        </div>

      </div>
      
      {
        suggestions && suggestions[pitchUploaderIX] ?
        <MiniProfile user={suggestions[pitchUploaderIX].user} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} className={`${styles["absolute"]} ${styles["z-20"]} ${styles["bottom-7"]} ${styles["left-1/2"]} ${styles["-translate-x-1/2"]} ${styles["w-4/5"]} ${styles["drop-shadow-md"]}`}></MiniProfile>
        : null
      }

    </Animated>
    <ErrorNotif errorText={errorText} onClose={onErrorNotifClose}></ErrorNotif>
  </div>);
};
