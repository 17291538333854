import { useCallback, useEffect, useReducer } from "react";
import { User } from "../../lib";
import { io } from "socket.io-client";

export const post = async (action: string, data: any[]): Promise<any> => {
  try {

    const res = await fetch(`/api2/${action}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      const errorData = await res.json();

      if (res.status === 401) {
        window.location.pathname = "/signout";
        window.location.reload();
        return undefined;
      }
    
      alert(
`Oh no! You found an error!
  
Status: ${res.status}
Error Code: ${errorData.errorID}
Description: ${errorData.errorDesc}

If you believe this error should not have occurred, please report this as an issue. We recommend you screenshot this message and record the error code. Badavas is still in Beta, but we are doing our best to resolve any issues. Thank you for your patience :>`
      );
    
      return undefined;
    }

    // these actions return BLOBs
    const blobActions = new Set(["get-profile-image", "get-pitch", "get-pitch-thumbnail"]);

    return blobActions.has(action) ? res.blob() : res.json();

  } catch (err) {
    console.log(err);
  }
};

export const getAllUsers = (): Promise<User[] | undefined> => {
  return post("get-all-users", []);
};

export const getCurrUser = (): Promise<User | false | undefined> => {
  return post("get-curr-user", []);
};

export const signIn = (userID: string, password: string): Promise<boolean | undefined> => {
  return post("sign-in", [userID, password]);
};

export const signOut = (): Promise<boolean> => {
  return post("sign-out", []);
};

export const getAllUsersWithPCs = (): Promise<[User, number][] | undefined> => {
  return post("get-all-users-with-p-cs", []);
};

export const getPitch = (uploaderUID: string, rowIX: number): Promise<Blob> => {
  return post("get-pitch", [uploaderUID, rowIX]);
};

export const getPitchThumbnail = (uploaderUID: string, rowIX: number): Promise<Blob> => {
  return post("get-pitch-thumbnail", [uploaderUID, rowIX]);
};

export const getPitchCount = (uploaderUID: string): Promise<number> => {
  return post("get-pitch-count", [uploaderUID]);
};

export const getPFP = (userID: string): Promise<Blob> => {
  return post("get-profile-image", [userID]);
};

export const getRecommendations = (): Promise<[User, number][] | undefined> => {
  return post("get-recommendations", []);
};

export const runSearch = (query: string): Promise<[User, User, User] | undefined> => {
  return post("suggest", [query]);
};

export const getRandomUsers = (cnt: number): Promise<User[] | undefined> => {
  return post("get-random-users", [cnt]);
};

export const getLastPostTime = (uid: string): Promise<number | undefined> => {
  return post("get-last-post-time", [uid]);
};

interface SimilarityData {
  score: number;  // 0-100 with 1 digit after the decimal point
  justification: string;
};

export const getSimilarity = (user1: User, user2: User): Promise<SimilarityData | undefined> => {
  return post("similarity", [user1, user2]);
};

export const addPitchView = (uploaderUID: string, rowIX: number): Promise<true | undefined> => {
  return post("add-pitch-view", [uploaderUID, rowIX]);
};

export const getPitchViews = (uploaderUID: string, rowIX: number): Promise<number | undefined> => {
  return post("get-pitch-views", [uploaderUID, rowIX]);
};

export const deletePitch = (rowIX: number): Promise<undefined> => {
  return post("delete-pitch", [rowIX]);
};

export const deletePost = (postID: string): Promise<true | undefined> => {
  return post("delete-post", [postID]);
};

export const getUser = (uid: string): Promise<User | undefined> => {
  return post("get-user", [uid]);
};

export const getIsSupporter = (uid: string): Promise<boolean | undefined> => {
  return post("get-is-supporter", [uid]);
};

export const checkPWResetCodeIsValid = (code: string): Promise<boolean | undefined> => {
  return post("check-pw-reset-code-is-valid", [code]);
};

export const activatePasswordResetCode = (code: string, newPassword: string): Promise<true | undefined> => {
  return post("activate-password-reset-code", [code, newPassword]);
};

export const activateVerifCode = (code: string): Promise<boolean | undefined> => {
  return post("activate-verif-code", [code]);
};

export enum PWCodeSendResponse { _, Success, NoUser, UnknownFailure };
export const sendPasswordResetEmail = (uid: string, email: string): Promise<PWCodeSendResponse> => {
  return post("send-password-reset-email", [uid, email]);
};

export type UserEditableProperty = "uid" | "type" | "username" | "description" | "wants" | "email" | "supporter_banner_url" | "password" | "avail";
export const updateUser = (component: UserEditableProperty, newValue: any): Promise<User | undefined> => {
  return post("update-user", [component, newValue]);
};

export const sendVerifEmail = (uid: string): Promise<true | undefined> => {
  return post("send-verif-email", [uid]);
};

export const checkUIDValidity = (uid: string): Promise<boolean> => {
  return post("check-uid-validity", [uid]);
};

enum UserType { company, individual };

export interface UserBlueprint {
  uid: string;
  type: UserType;
  username: string;
  email: string;
  password: string;
  description: string;
  wants: string;
};

export const createUser = (userBlueprint: UserBlueprint): Promise<boolean | undefined> => {
  return post("create-user", [userBlueprint]);
};



export interface ConversationData {
  otherUser: User;
  unreadMessage: boolean;
  acceptedConv: boolean;   // unused, ignore for now
};

export interface MessageData {
  sender: User;
  reciever: User;
  timeSent: string;   // date string
  message: string;  // message text, yea not the best name bc can be more specific and use new vocab, was from a diff context :/
};

export const getConversations = async (): Promise<ConversationData[]> => {
  return await post("get-conversations", []);
};

export const useConversations = (currUser: User): { conversations: ConversationData[], locallyMarkRead: (otherUID: string) => void } => {
  const [conversations, updateConversations] = useReducer((state: ConversationData[], action: { type: "SET", newValue: ConversationData[] } | { type: "LOCALLY MARK READ", otherUID: string }) => {
    switch (action.type) {
      case "SET":
        return action.newValue;  // no break necessary bc return will end switch already
      case "LOCALLY MARK READ":
        for (let ix=0;ix<state.length;ix++) {
          if (state[ix].otherUser.uid === action.otherUID) {
            state[ix].unreadMessage = false;
          }
        }
        return state;
    }
  }, []);

  const locallyMarkRead = useCallback((otherUID: string) => {
    updateConversations({ type: "LOCALLY MARK READ", otherUID });
  }, []);

  useEffect(() => {
    getConversations().then((res: ConversationData[]) => {
      updateConversations({ type: "SET", newValue: res });
    });

    const socket = io({ query: { thisUID: currUser.uid } });

    socket.on("nm", async () => {
      updateConversations({ type: "SET", newValue: await getConversations() });
    });

    const unsubscribe = () => { socket.off("nm"); socket.close(); };

    window.addEventListener("popstate", unsubscribe);

    return () => {
      unsubscribe();
      window.removeEventListener("popstate", unsubscribe);
    };
  }, [currUser.uid]);

  return { conversations, locallyMarkRead };

};

export const getMessages = async (otherUID: string, limit: number): Promise<MessageData[] | null> => {
  return (await post("get-messages", [otherUID, limit]))?.reverse() ?? null;
};

export const sendMessage = async (otherUID: string, message: string): Promise<true> => {
  return await post("send-message", [otherUID, message]);
};

export const getMessageCount = async (otherUID: string): Promise<number> => {
  return await post("get-message-count", [otherUID]);
};



export interface PostData {
  postid: string;    // can be used in endpoints to reference the post
  sender: User;
  timesent: number;  // to convert to legible date: new Date(postObj.timesent).toLocaleString()
  title: string;
  value: string;     // the content of the post in sanitized HTML
  replyTo: null;     // unused, ignore for now
};

export interface GetRootPostOptions {
  targetUID?: string,
  limit: number,
};

export const getRootPosts = async (options: GetRootPostOptions): Promise<PostData[] | undefined> => {
  return post("get-root-posts", [options.targetUID ?? null, options.limit]);
};

export const postPost = async (sanitizedHTML: string, title: string): Promise<true | undefined> => {
  return post("post-post", [sanitizedHTML, title]);
};

export const postFPost = async (): Promise<true | undefined> => {
  return post("post-f-post", []);
};

export const getPostCount = async (targetUID?: string): Promise<number | undefined> => {
  return post('get-post-count', [targetUID ?? null]);
};
